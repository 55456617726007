import { Injectable, Inject } from '@angular/core';
import { BaseStateService } from './base-state.service';
import { treatmentPlanPhases, logDefinitions, sleepFacts } from '../../constants/constants';
import { UserStateService } from './user-state';
import {
  AssessmentTemplate,
  UserAction,
  UserActionType,
  AssessmentQuestion,
  Assessment,
  CalendarEvent,
  SleepPrescription,
  MedicalPrescription,
  SleepPrescriptionFlag,
  AssessmentAnswer,
  AssessmentGroup
} from '../services/models';
import { Router } from '@angular/router';
import {
  AssessmentService,
  CalendarEventService,
  SleepPrescriptionService,
  MedicalPrescriptionService,
  AssessmentDefinitionService
} from '../services/services';
import { some as _some, maxBy as _maxBy, find as _find } from 'lodash';
import { Subject } from 'rxjs';
import { filter } from 'rxjs/operators';
import { ModelFactory, USER_STATE_SERVICE } from '../../core/web-ng';
import { TranslationService } from '../translations';
import { Meta } from '../../core';
import moment from 'moment';
import { LegacyService } from '../services/legacy.service';
// TODO: move to models

export class TreatmentPlanState {
  isLoading: boolean;
  treatmentPlanStartDate: string;
  currentPhase: TreatmentPhase;
  phases: Array<TreatmentPhase>;
  followupPhases: Array<TreatmentPhase>;
  currentUserActions: Array<UserAction>;
  currentAssessmentQuestions: Array<AssessmentQuestion>;
  currentAssessmentTerm: TreatmentPhase;
  completedAssessments: Array<Assessment>;
  inProgressAssessments: Array<Assessment>;
  sleepFacts: Array<string>;
  alertMessage: string;
  calendarEvents: Array<CalendarEvent>;
  currentCalendarEvent: CalendarEvent;
  currentCalendarEventExpired: boolean;
  currentSleepPrescription: SleepPrescription;
  sleepPrescriptions: Array<SleepPrescription>;
  currentMedications: Array<MedicalPrescription>;
  isModalDisplayed: boolean;
}

export class TreatmentPhase {
  name: string;
  label: string;
  order: number;
  assessments: Array<string>;
  baselineAssessments?: Array<string>;
  conditionalAssessments?: Array<any>;
  endDate?: string | Date;
}

@Injectable()
export class TreatmentPlanStateService extends BaseStateService<TreatmentPlanState> {
  public onCurrentSleepPrescription$: Subject<SleepPrescription[]> = new Subject<
    SleepPrescription[]
  >();
  public onTotalPoints$: Subject<boolean> = new Subject<boolean>();

  constructor(
    translationService: TranslationService,
    stateFactory: ModelFactory<TreatmentPlanState>,
    @Inject(USER_STATE_SERVICE) private userStateService: UserStateService,
    private router: Router,
    private assessmentService: AssessmentService,
    private calendarEventService: CalendarEventService,
    private sleepPrescriptionService: SleepPrescriptionService,
    private medicalPrescriptionService: MedicalPrescriptionService,
    private legacyService: LegacyService,
    private assessmentDefinitionService: AssessmentDefinitionService
  ) {
    super(new TreatmentPlanState(), stateFactory, translationService);

    this.initialize();
  }

  public async initialize() {
    let state = this.getState();
    let user: any = this.userStateService.model.get().User;
    const now = moment.utc().add(moment().utcOffset(), 'm').endOf('day');
    this.userStateService.state$
      .pipe(filter(userState => !!userState.User))
      .subscribe(async userState => {
        this.assessmentDefinitionService.initialize();
        user = userState.User;
        state.currentMedications = await this.medicalPrescriptionService.getAllAsync({
          'Payload.patient.id': user.UserId
        });
        const savedAssessments = await this.assessmentService.getAllAsync({
          'Payload.user.id': user.UserId,
          'Auditing.CreatedBy': user.UserId
        });
        state.completedAssessments = savedAssessments?.filter(a => !a.isIncomplete);
        state.inProgressAssessments = savedAssessments?.filter(a => a.isIncomplete);
        state.calendarEvents = await this.calendarEventService.getAllAsync({
          $and: [{ 'Payload.user.id': user.UserId }]
        });
        state.calendarEvents.sort((a, b) => (a.startDate > b.startDate) ? 1 : -1);
        state.phases = this.mapCalendarEventsToPhases(state.calendarEvents);
        const currentSleepPrescriptions = await this.sleepPrescriptionService.getAllAsync({
          $and: [{ 'Payload.patient.id': user.UserId }, { 'Payload.startDate': { $lte: now } }]
        });
        state.sleepPrescriptions = currentSleepPrescriptions;
        state.currentSleepPrescription = this.getCurrentSleepPrescription(currentSleepPrescriptions);
        state.sleepFacts = this.shuffleArray(sleepFacts);
        state.treatmentPlanStartDate = user.treatmentPlanStartDate;
        this.setState(state);

        state = this.getCurrentPhase();

        this.setState(state);
        state.currentUserActions = await this.buildUserActionList(state.currentPhase);

        this.setState(state);
        
        // Debug. While this prints in the clinician portal it only makes sence in the patient app as it is specific to the logged-in user
        // console.debug (user, state.currentPhase, state.currentUserActions);
      });
  }

  private findCurrentPhase(event, index, events) {
    let endDate: moment.Moment;
    const nowDate = moment.utc().add(moment().utcOffset(), 'm');

    if (event.phaseName === 'checkin') {
      return null;
    }
    if (
      event.isLegacy === undefined &&
      (event.phaseName === 'post' ||
        event.phaseName === 'followup1' ||
        event.phaseName === 'followup2' ||
        event.phaseName === 'followup3')
    ) {
      let nextWeek: CalendarEvent = null;
      switch (event.phaseName) {
        case 'post':
          nextWeek = _find(events, week => week.phaseName === 'followup1');
          endDate = moment.utc(nextWeek.startDate).subtract(1, 'day').endOf('day').local();
          break;
        case 'followup1':
          nextWeek = _find(events, week => week.phaseName === 'followup2');
          endDate = moment.utc(nextWeek.startDate).subtract(1, 'day').endOf('day').local();
          break;
        case 'followup2':
          nextWeek = _find(events, week => week.phaseName === 'followup3');
          endDate = moment.utc(nextWeek.startDate).subtract(1, 'day').endOf('day').local();
          break;
        case 'followup3':
          const prevWeek: CalendarEvent = _find(events, week => week.phaseName === 'followup3');
          endDate = moment
            .utc(prevWeek.startDate)
            .add(4 * 7 * 6 + 14, 'days')
            .endOf('day')
            .local();
      }
    } else {
      endDate = moment.utc(event.endDate).local();
    }
    const startDate = moment.utc(event.startDate).local();
    return nowDate.isBetween(startDate, endDate, 'days', '[]');
  }

  private getCurrentPhase(): TreatmentPlanState {
    const state = this.getState();

    if (state.calendarEvents && state.calendarEvents.length > 0) {
      let currentEvent = state.calendarEvents.find(this.findCurrentPhase);
      if (!currentEvent) {
        const previousEvents = state.calendarEvents.filter(
          event =>
            moment.utc().add(moment().utcOffset(), 'm').diff(moment.utc(event.endDate), 'days') >= 0
        ); // event.endDate < now
        currentEvent = _maxBy(previousEvents, event => event.endDate);
        state.currentCalendarEventExpired = true;
      }
      state.currentCalendarEvent = currentEvent;

      if (currentEvent) {
        this.onTotalPoints$.next(!!currentEvent.pointsNotificationReadOn);
      }
      const phase = state.phases.find(phase => phase.name === currentEvent?.phaseName);

      state.currentPhase = phase || null;
      return state;
    } else {
      state.currentPhase = Object.values(treatmentPlanPhases)[0];
      return state;
    }
  }

  public async calendarEventNotificationRead() {
    const state = this.getState();
    if (state.currentCalendarEvent) {
      const now = new Date().toISOString();
      state.currentCalendarEvent.pointsNotificationReadOn = now;
      await this.calendarEventService.saveAsync(state.currentCalendarEvent);
      this.setState(state);
    }
  }

  public async sleepPrescriptionNotificationRead() {
    const state = this.getState();
    if (state.currentSleepPrescription) {
      const now = new Date().toISOString();
      state.currentSleepPrescription.notificationReadOn = now;
      await this.sleepPrescriptionService.saveAsync(state.currentSleepPrescription);
      this.setState(state);
    }
  }

  public isCurrentPhaseEnded(): boolean {
    const state = this.getState();
    if (state) {
      return !!state.currentCalendarEventExpired;
    }
  }

  private getCurrentSleepPrescription(sleepPrescriptions: Array<SleepPrescription>): SleepPrescription {
    if (!sleepPrescriptions || sleepPrescriptions.length === 0) {
      return null;
    }
    const sleepPrescription =
      _maxBy(sleepPrescriptions, rx => rx.createdOn) || sleepPrescriptions[0];
    if (sleepPrescription) {
      sleepPrescription.clientTimeZoneOffset = moment().utcOffset();
      this.sleepPrescriptionService
        .update(sleepPrescription)
        .subscribe(sub => this.onCurrentSleepPrescription$.next(sleepPrescriptions));
    }
    return sleepPrescription;
  }

  public async buildUserActionList(phase: TreatmentPhase, refreshAssessments?: boolean) {
    const userActions: Array<UserAction> = [];
    const state = this.getState();
    if (refreshAssessments) {
      const user: any = this.userStateService.model.get().User;
      const savedAssessments = await this.assessmentService.getAllAsync({
        $and: [{ 'Payload.user.id': user.UserId }]
      });
      state.completedAssessments = savedAssessments.filter(a => !a.isIncomplete);
      state.inProgressAssessments = savedAssessments.filter(a => a.isIncomplete);
      this.setState(state);
    }

    if (!phase) return [];

    const completedAssessments = this.getAssessmentsInPhase(phase, false);
    const groups = this.getGroupsForAssessments(completedAssessments);
    const assessments = this.filterAssessmentsOnly(phase);
    const incompleteAssessments = this.filterIncompleteAssessmentsOnly(assessments, groups, phase);

    if (phase.name === 'baseline') {
      userActions.push({
        userActionType: UserActionType.quickStart.name,
        displayText: UserActionType.quickStart.label,
        subText: '',
        route: '/info/quickstart',
        questions: null,
        phaseName: phase
      });

      const initialBaselineAssessment = this.getBaselineAssessment(phase, groups);
      userActions.push(initialBaselineAssessment);
    }

    if (this.isAssessmentAvailable(phase)) {
      const userAction = this.buildAssessmentUserAction(
        assessments,
        UserActionType.assessment,
        incompleteAssessments,
        phase
      );

      const currentEvent = state.currentCalendarEvent;
      let endShowAssessmentDate: moment.Moment;
      const currentDate = moment().startOf('day');
      
      // Adding this check to resolve error due to currentEvent being undefined
      // TODO : Investigate root cause of currentEvent being undefined
      if (currentEvent && (currentEvent?.isLegacy === undefined) &&
        (currentEvent.phaseName === 'post' ||
          currentEvent.phaseName === 'followup1' ||
          currentEvent.phaseName === 'followup2' ||
          currentEvent.phaseName === 'followup3')
      ) {
        endShowAssessmentDate = this.legacyService.getLegacyEndDate(
          currentEvent,
          state.calendarEvents
        );
      } else {
        endShowAssessmentDate = moment.utc(currentEvent.endDate).endOf('day');
      }

      if (userAction) {
        userAction.isExpired = currentDate > endShowAssessmentDate;
        userActions.push(userAction);
      }
    } else if (state.calendarEvents) {
      const now = new Date();
      const previousCalendarEvent = state.calendarEvents.find(
        ce =>
          ce.phaseName !== state.currentCalendarEvent?.phaseName &&
          !ce.isComplete &&
          ce.endDate > now &&
          ce.startDate < now
      );
      if (previousCalendarEvent) {
        const previousPhase = state.phases
          ? state.phases.find(phase => phase.name === previousCalendarEvent.phaseName)
          : null;
        const completedAssessmentsPrevious = this.getAssessmentsInPhase(previousPhase, false);
        const groups = this.getGroupsForAssessments(completedAssessmentsPrevious);
        const previousPhaseAssessments = this.filterAssessmentsOnly(previousPhase);

        const previousPhaseIncompleteAssessments = this.filterIncompleteAssessmentsOnly(
          previousPhaseAssessments,
          groups,
          phase
        );

        const previousUserAction = this.buildAssessmentUserAction(
          previousPhaseAssessments,
          UserActionType.assessment,
          previousPhaseIncompleteAssessments,
          previousPhase
        );
        if (previousUserAction) {
          userActions.push(previousUserAction);
        }
      }
    }

    const logs = phase.assessments?.filter(
      assessment =>
        assessment === AssessmentTemplate.morningLog.name ||
        assessment === AssessmentTemplate.eveningLog.name
    );
    if (logs && logs.length > 0) {
      userActions.push({
        userActionType: UserActionType.logs.name,
        displayText: UserActionType.logs.label,
        route: '/logs',
        questions: null,
        phaseName: phase
      });
    }

    const phaseSleepPrescription = state.sleepPrescriptions
      ? state.sleepPrescriptions.find(sleepRx => sleepRx.phaseName === phase.name)
      : null;

    if (phaseSleepPrescription) {
      if (phaseSleepPrescription.isRestless) {
        const nightmareUserAction = this.createSleepTactic(
          'isRestlessReadOn',
          'Practice Sleep Intensity Training',
          phase,
          '/info/sleep-intensity-training'
        );
        if (nightmareUserAction) {
          userActions.push(nightmareUserAction);
        }
      }
      if (phaseSleepPrescription.isThinking) {
        const nightmareUserAction = this.createSleepTactic(
          'isThinkingReadOn',
          'Practice Thinking Efficiency',
          phase,
          '/info/thinking-efficiency-retraining'
        );
        if (nightmareUserAction) {
          userActions.push(nightmareUserAction);
        }
      }
      if (phaseSleepPrescription.isNightmare) {
        const nightmareUserAction = this.createSleepTactic(
          'isNightmareReadOn',
          'Practice Dream Retraining',
          phase,
          '/info/dream-retraining'
        );
        if (nightmareUserAction) {
          userActions.push(nightmareUserAction);
        }
      }
      if (phaseSleepPrescription.patientFlags && phaseSleepPrescription.patientFlags.length > 0) {
        if (this.isFlagged(phaseSleepPrescription.patientFlags, 'IsStressReduction')) {
          const stressUserAction = this.createSleepTactic(
            'isStressReadOn',
            'Practice Stress Reduction',
            phase,
            '/info/stress-reduction-tactics'
          );
          if (stressUserAction) {
            userActions.push(stressUserAction);
          }
        }
        if (this.isFlagged(phaseSleepPrescription.patientFlags, 'IsFatgiueCounter')) {
          const fatigueUserAction = this.createSleepTactic(
            'isFatigueReadOn',
            'Practice Fatigue Countermeasures',
            phase,
            '/info/fatigue-countermeasures'
          );
          if (fatigueUserAction) {
            userActions.push(fatigueUserAction);
          }
        }
        if (this.isFlagged(phaseSleepPrescription.patientFlags, 'IsClockRetraining')) {
          const clockUserAction = this.createSleepTactic(
            'isClockReadOn',
            'Practice Clock Retraining',
            phase,
            '/info/clock-retraining'
          );
          if (clockUserAction) {
            userActions.push(clockUserAction);
          }
        }
        if (this.isFlagged(phaseSleepPrescription.patientFlags, 'IsThoughtSubstitution')) {
          const clockUserAction = this.createSleepTactic(
            'isThoughtSubstitutionReadOn',
            'Practice Thought Substitution',
            phase,
            '/info/thought-substitution-training'
          );
          if (clockUserAction) {
            userActions.push(clockUserAction);
          }
        }
      }
    }

    return userActions;
  }

  private filterAssessmentsOnly(phase: TreatmentPhase, isBaseline?: boolean) {
    if (!phase) {
      return [];
    }
    const type = isBaseline ? 'baselineAssessments' : 'assessments';
    const list = phase[type]?.filter(
      assessment =>
        assessment !== AssessmentTemplate.morningLog.name &&
        assessment !== AssessmentTemplate.eveningLog.name
    );

    if (list && list.length > 0 && phase.conditionalAssessments?.length > 0) {
      const state = this.getState();
      phase.conditionalAssessments.forEach(c => {
        const conditionPhase = state.completedAssessments?.find(
          a => a.phaseName === c.conditionPhase
        );
        if (conditionPhase) {
          const conditionAssessment = conditionPhase.groups.find(
            g => g.type === c.conditionAssessment
          );
          if (conditionAssessment) {
            const conditionAnswer = conditionAssessment.answers.find(
              a => a.uniqueAnswerId === c.conditionField
            );
            const options = c.options.find(o => o.value === conditionAnswer.value);
            if (options?.addAfterAssessment) {
              const index = list.indexOf(options.addAfterAssessment);
              list.splice(index + 1, 0, ...options.assessmentsToAdd);
            }
          }
        }
      });
    }
    return list;
  }

  private filterIncompleteAssessmentsOnly(
    assessmentTypes: string[],
    groups: AssessmentGroup[],
    phase: TreatmentPhase
  ) {
    if (!groups || groups.length === 0) {
      return assessmentTypes;
    }
    const groupsToIgnore = new Set<string>();
    const groupslist = new Set<string>();
    assessmentTypes.forEach(type => {
      groupslist.add(type);
      const assessmentObj = this.assessmentDefinitionService.getByName(type);
      if (assessmentObj && assessmentObj.questions) { //Checking for undefined baseline assessments.  TODO: use filtering on baseline assessments
        const redirectQuestions = assessmentObj.questions.filter(q => q.redirectOptions !== null);
        redirectQuestions.forEach(r => {
          // get answers question
          const grp = groups.find(g => g.type === type);
          if (grp && r.redirectOptions) { //Checking for undefined assessment questions
            const answer = grp.answers.find(a => a.uniqueAnswerId === r.uniqueAnswerId);
            const redirectOption = r.redirectOptions.find(o => o.value === answer.value);
            redirectOption?.assessmentsToRemove.forEach(r => {
              groupsToIgnore.add(r);
            });
            redirectOption?.assessmentsToAdd.forEach(a => {
              groupslist.add(a);
            });
          }
        });
      }
      groupsToIgnore.forEach(i => {
        groupslist.delete(i);
      });
    });
    const list = [...groupslist].filter(type => !_some(groups, group => group.type === type));
    return list;
  }

  private getGroupsForAssessments(assessments: any) {
    let groups = [];
    for (const ca of assessments) {
      groups = groups.concat(ca.groups);
    }
    return groups;
  }

  public isFlagged(flags: Array<SleepPrescriptionFlag>, property: string) {
    return _some(flags, flag => flag.name === property && flag.isSelected);
  }

  private createSleepTactic(
    readProperty: string,
    display: string,
    phase: TreatmentPhase,
    route: string
  ) {
    const state = this.getState();
    const calEvent = state.calendarEvents.find(e => e.phaseName === phase.name);
    let subText = null;
    const expirationDate = moment.utc(calEvent.endDate);
    if (state.currentSleepPrescription[readProperty]) {
      const subTextDate = moment.utc(state.currentSleepPrescription[readProperty]);
      if (expirationDate.isBefore(moment(), 'days') || expirationDate.isSame(moment(), 'days')) {
        subText = `Last Read: ${subTextDate.format('M/D/YYYY')}`;
      } else {
        subText = calEvent ? `Continue Until: ${expirationDate.format('M/D/YYYY')}` : null;
      }
    } else {
      subText = calEvent ? `Continue Until: ${expirationDate.format('M/D/YYYY')}` : null;
    }

    return {
      userActionType: UserActionType.readItem.name,
      displayText: display,
      subText,
      route,
      questions: null,
      phaseName: phase
    };
  }

  private buildAssessmentUserAction(
    assessments: Array<string>,
    userActionType: UserActionType,
    incompleteAssessmentNames: Array<string>,
    phaseName: TreatmentPhase,
    isBaseline?: boolean
  ): UserAction {
    const state = this.getState();
    const inProgress = state.inProgressAssessments?.filter(
      assessment => assessment.phaseName === phaseName.name
    );
    let subText = '';
    if (state.calendarEvents) {
      const event = state.calendarEvents.find(e => e.phaseName === phaseName.name);
      let dueBy: string;
      if (event) {
        // Once we no longer have legacy events this can be removed
        if (event?.isLegacy === undefined &&
          (event.phaseName === 'post' ||
            event.phaseName === 'followup1' ||
            event.phaseName === 'followup2' ||
            event.phaseName === 'followup3')
        ) {
          dueBy = this.legacyService
            .getLegacyEndDate(event, state.calendarEvents)
            .format('M/D/YYYY');
          subText = `Due By: ${dueBy}`;
        } else {
          dueBy = moment.utc(event.endDate).format('M/D/YYYY');
          subText = `Due By: ${dueBy}`;
        }
      }
    }
    if (assessments && assessments.length > 0) {
      let questions = [];
      for (const assessmentName of incompleteAssessmentNames) {
        //special handling since sus/sus2 need to be supported on legacy app
        if(assessmentName===AssessmentTemplate.sus.name || assessmentName===AssessmentTemplate.sus2.name){
          continue;
        }
        let assessment = this.assessmentDefinitionService.getByName(assessmentName);
        if(assessmentName===AssessmentTemplate.phq8.name && (!assessment.questions || assessment.questions.length === 0)){ //special handling to convert phq8 to phq2 for 1.5
          assessment = this.assessmentDefinitionService.getByName(AssessmentTemplate.phq2.name);  
        }
        else if (assessmentName===AssessmentTemplate.phq8.name && assessment?.questions.length) {
          // Note: this is terrible but given the above hack, the fact that there is no entry in the database for the PHQ2, and the special need now for Intermountain this feels the safest. My sincere apologies if this trips anyone up in the future but hopefully this is rebuilt before then ...
          continue;
        }
        else if(assessmentName===AssessmentTemplate.gad7.name && (!assessment.questions || assessment.questions.length === 0)){ //special handling to convert phq8 to phq2 for 1.5
          assessment = this.assessmentDefinitionService.getByName(AssessmentTemplate.gad2.name);  
        }
        if (assessment && assessment.questions && assessment.questions.length > 0) {
          //special handling for shiftWork, in 1.5 it will be only asked in Baseline
          if(assessmentName===AssessmentTemplate.shiftWork.name && assessment.questions.length===1 && phaseName.name !== 'baseline'){
            continue;
          }
          for (const question of assessment.questions) {
            const assessmentQuestion: AssessmentQuestion = question;
            assessmentQuestion.assessmentName = assessment.name;
            if (assessmentQuestion.conditionalQuestions?.length > 0) {
              for (let i = 0; i < assessmentQuestion.conditionalQuestions.length; i++) {
                assessmentQuestion.conditionalQuestions[i].assessmentName = assessment.name;
              }
            }
            questions.push(assessmentQuestion);
            if (question.redirectOptions) {
              const answer = this.getAnswerFromAssessments(inProgress, question.uniqueAnswerId);

              if (answer != null) {
                // If the question has conditional assessments that are based on multiple specific answers
                if (question.redirectMustMeetSpecificValues === true) {
                  this.handleRedirectsWithSpecificValues(question, questions);
                } else {
                  const redirectOption = question.redirectOptions.find(o => o.value === answer);

                  redirectOption.assessmentsToAdd.forEach(asmt => {
                    const assessmentQuestions = this.assessmentDefinitionService.getByName(asmt).questions;
                    assessmentQuestions.forEach(q => {
                      q.assessmentName = asmt;
                      questions.push(q);
                    });
                  });
                }
              }
            }
          }
        }
        if (assessment && assessment.name === AssessmentTemplate.sleepGoalsPost.name) {
          const postQuestions = this.getSleepGoalsPostQuestions();
          if (postQuestions && postQuestions.length > 0) {
            questions = questions.concat(postQuestions);
          }
        } else if (assessment && assessment.name === AssessmentTemplate.medicationUse.name) {
          const medUseQuestions = this.getMedUseQuestions();
          if (medUseQuestions && medUseQuestions.length > 0) {
            questions = questions.concat(medUseQuestions);
          }
        }
      }
      const state = this.getState();
      if (state.inProgressAssessments && state.inProgressAssessments.length > 0) {
        questions = questions.map(question => this.assignQuestions(question, inProgress));
      }
      return {
        userActionType: userActionType.name,
        displayText: userActionType.label,
        subText,
        route: 'logs/assessment',
        questions,
        phaseName
      };
    } else {
      return null;
    }
  }

  private getAnswerFromAssessments(assessments: Array<Assessment>, uniqueAnswerId: string) {
    let returnVal = null;
    assessments?.forEach(assessment => {
      assessment.groups.forEach(g => {
        g.answers.forEach(a => {
          if (a.uniqueAnswerId === uniqueAnswerId) {
            returnVal = a.value;
          }
        });
      });
    });
    return returnVal;
  }

  private assignQuestions(question: AssessmentQuestion, assessments: Array<Assessment>) {
    if (question.uniqueAnswerId) {
      const answers = [];
      for (const ca of assessments) {
        for (const group of ca.groups) {
          group.answers.forEach(answer => {
            answers.push(answer);
            if (answer.conditionalQuestionAnswers) {
              answer.conditionalQuestionAnswers.forEach(conditional => {
                answers.push(conditional);
              });
            }
          });
        }
      }

      const existingAnswer = answers.find(
        answer =>
          answer.uniqueAnswerId &&
          answer.uniqueAnswerId === question.uniqueAnswerId &&
          (!!answer.value || answer.value === 0 || answer.value === false)
      );
      if (existingAnswer) {
        question.answer = existingAnswer.value;
      }
      question.conditionalQuestions.forEach(conditional => {
        conditional = this.assignQuestions(conditional, assessments);
      });
      return question;
    }
  }

  private getSleepGoalsPostQuestions() {
    const state = this.getState();
    let answers = [];
    const questions = [];
    if (state.completedAssessments) {
      for (const assessment of state.completedAssessments) {
        for (const group of assessment.groups) {
          if (group.type === AssessmentTemplate.sleepGoalsPre.name) {
            answers = answers.concat(
              group.answers?.filter(
                answer =>
                  answer.value === true ||
                  answer.value === 'true' ||
                  answer.uniqueAnswerId === 'SLEEP_CHALLENGES_PRE_OTHER' ||
                  answer.uniqueAnswerId === 'SLEEP_GOALS_PRE_OTHER'
              )
            );
          }
        }
      }
    }

    if (answers && answers.length > 0) {
      const sleepGoalsPre = this.assessmentDefinitionService.getByName(AssessmentTemplate.sleepGoalsPre.name);
      const questionDefinitions: Array<AssessmentQuestion> = sleepGoalsPre.questions;
      const patientGoals = answers.filter(answer =>
        answer.uniqueAnswerId.startsWith('SLEEP_GOALS_PRE')
      );
      let goalText = '';
      const filteredPatientGoals: Array<string> = [];
      for (const goal of patientGoals) {
        const questionEmphasis = questionDefinitions.find(
          q => q.uniqueAnswerId === goal.uniqueAnswerId
        )?.questionEmphasis;
        const text =
          goal.uniqueAnswerId === 'SLEEP_GOALS_PRE_OTHER' ? goal.value : questionEmphasis;
        if (text != 'NA') filteredPatientGoals.push(text);
        goalText = `${goalText}${text}; `;
      }
      const patientChallenges = answers.filter(answer =>
        answer.uniqueAnswerId.startsWith('SLEEP_CHALLENGES_PRE')
      );
      let challengeText = '';
      const filteredPatientChallenges: Array<string> = [];
      for (const challenge of patientChallenges) {
        const questionEmphasis = questionDefinitions.find(
          q => q.uniqueAnswerId === challenge.uniqueAnswerId
        )?.questionEmphasis;
        const text =
          challenge.uniqueAnswerId === 'SLEEP_CHALLENGES_PRE_OTHER'
            ? challenge.value
            : questionEmphasis;
        if (text != 'NA') filteredPatientChallenges.push(text);
        challengeText = `${challengeText}${text}; `;
      }

      if (filteredPatientGoals && filteredPatientGoals.length > 0) {
        filteredPatientGoals.forEach(question => {
          questions.push({
            questionText:
              'When beginning the NOCTEM training, you selected the following goal. Please rate the extent to which you believe you have met this goal on a scale of 0 (Did not meet at all) to 10 (Completely met goal):',
            questionEmphasis: `${question}`,
            questionType: 'select',
            autofill: false,
            answerOptions: [
              { display: '0', value: 0 },
              { display: '1', value: 1 },
              { display: '2', value: 2 },
              { display: '3', value: 3 },
              { display: '4', value: 4 },
              { display: '5', value: 5 },
              { display: '6', value: 6 },
              { display: '7', value: 7 },
              { display: '8', value: 8 },
              { display: '9', value: 9 },
              { display: '10', value: 10 }
            ],
            conditionalQuestions: [],
            assessmentName: AssessmentTemplate.sleepGoalsPost.name,
            uniqueAnswerId: 'SLEEP_GOALS_POST_ACHIEVED'
          });
        });
      }
      if (filteredPatientChallenges && filteredPatientChallenges.length > 0) {
        filteredPatientChallenges.forEach(challenge => {
          questions.push({
            questionText:
              "When beginning the NOCTEM training, you anticipated the below challenge. Please rate the extent to which you believe this challenge's difficulty actually was on a scale of 0 (Not at all Difficult) to 10 (Extremely difficult):",
            questionEmphasis: `${challenge}`,
            questionType: 'select',
            autofill: false,
            answerOptions: [
              { display: '0', value: 0 },
              { display: '1', value: 1 },
              { display: '2', value: 2 },
              { display: '3', value: 3 },
              { display: '4', value: 4 },
              { display: '5', value: 5 },
              { display: '6', value: 6 },
              { display: '7', value: 7 },
              { display: '8', value: 8 },
              { display: '9', value: 9 },
              { display: '10', value: 10 }
            ],
            conditionalQuestions: [],
            assessmentName: AssessmentTemplate.sleepGoalsPost.name,
            uniqueAnswerId: 'SLEEP_CHALLENGES_POST_DIFFICULT'
          });
        });
      }
    }
    return questions;
  }

  private getMedUseQuestions() {
    const state = this.getState();
    const questions = [];
    for (const med of state.currentMedications) {
      questions.push({
        questionText: `In the past week I used ${med.medicine.display} for ___ out of 7 nights:`,
        questionType: 'select',
        autofill: false,
        answerOptions: [
          { display: '0', value: 0 },
          { display: '1', value: 1 },
          { display: '2', value: 2 },
          { display: '3', value: 3 },
          { display: '4', value: 4 },
          { display: '5', value: 5 },
          { display: '6', value: 6 },
          { display: '7', value: 7 }
        ],
        conditionalQuestions: [],
        assessmentName: AssessmentTemplate.medicationUse.name,
        uniqueAnswerId: `MEDICATION_USE_${med.medicine.display.toUpperCase()}`
      });
    }
    return questions;
  }

  private getBaselineAssessment(phase: TreatmentPhase, groups): UserAction {
    const baseLineAssessments = this.filterAssessmentsOnly(phase, true);

    const incompleteAssessments = this.filterIncompleteAssessmentsOnly(
      baseLineAssessments,
      groups,
      phase
    );

    const userAction = this.buildAssessmentUserAction(
      baseLineAssessments,
      UserActionType.baselineAssessment,
      incompleteAssessments,
      phase,
      true
    );

    return userAction;
  }

  private isAssessmentAvailable(phase: TreatmentPhase) {
    const state = this.getState();
    const calendarEvent: CalendarEvent = state.calendarEvents
      ? state.calendarEvents.find(event => event.phaseName === phase.name)
      : null;
    if (calendarEvent) {
      const currentDate = moment().startOf('day');

      let endShowAssessmentDate: moment.Moment;

      // This can be removed when we no longer have legacy events in the database
      if (
        calendarEvent.isLegacy === undefined &&
        (calendarEvent.phaseName === 'post' ||
          calendarEvent.phaseName === 'followup1' ||
          calendarEvent.phaseName === 'followup2' ||
          calendarEvent.phaseName === 'followup3')
      ) {
        endShowAssessmentDate = this.legacyService.getLegacyEndDate(
          calendarEvent,
          state.calendarEvents
        );
      } else {
        endShowAssessmentDate = moment.utc(calendarEvent.endDate).local();
      }
      // Add all the old assessments to show as crossed out
      if (currentDate > endShowAssessmentDate) {
        return true;
      } else {
        /* If they are in phases post - followUp3 the assessment will be available 5 days 
        before the end of the phase, if not then just 2 days for any other phase */
        const daysWithAssessment =
          calendarEvent.phaseName === 'post' ||
          calendarEvent.phaseName === 'followup1' ||
          calendarEvent.phaseName === 'followup2' ||
          calendarEvent.phaseName === 'followup3'
            ? -4
            : -1;

        // Add assessments that fall within the show and end dates
        const beginShowAssessmentDate: moment.Moment = moment
          .utc(endShowAssessmentDate)
          .add(daysWithAssessment, 'days')
          .startOf('day')
          .local();
        const activeDate: boolean = currentDate.isBetween(
          beginShowAssessmentDate,
          endShowAssessmentDate,
          'days',
          '[]'
        );

        return activeDate;
      }
    } else {
      return false;
    }
  }

  private addDays(initialDate: Date, daysToAdd: number) {
    return new Date(initialDate.getTime() + daysToAdd * 24 * 60 * 60 * 1000);
  }

  private isAssessmentActionComplete(
    phase: TreatmentPhase,
    isBaselineAssessment?: boolean
  ): boolean {
    const phaseAssessments = this.getAssessmentsInPhase(phase, isBaselineAssessment);
    if (!phaseAssessments || phaseAssessments.length === 0) {
      return false;
    }
    let phaseAssessment: any = null;

    if (isBaselineAssessment && phaseAssessments[0].groups.find(g => g.type === 'isi')) {
      // baseline has two asessments.
      // The baseline entry battery has the trauma question
      phaseAssessment = phaseAssessments[0];
    } else if (
      phase.name === 'baseline' &&
      !phaseAssessments[0].groups.find(g => g.type === 'isi')
    ) {
      // regular weekly assessment does NOT have trauma question
      phaseAssessment = phaseAssessments[0];
    } else if (phase.name !== 'baseline') {
      phaseAssessment = phaseAssessments[0];
    }

    return phaseAssessment && !phaseAssessment.isIncomplete;
  }

  private isReadItemComplete(userAction: UserAction, phase: TreatmentPhase): boolean {
    const state = this.getState();
    let prescription: SleepPrescription;
    if (phase) prescription = state.sleepPrescriptions.find(p => p.phaseName === phase.name);
    else prescription = state.currentSleepPrescription;
    switch (userAction.route) {
      case '/info/sleep-intensity-training':
        return !!prescription.isRestlessReadOn;
      case '/info/thinking-efficiency-retraining':
        return !!prescription.isThinkingReadOn;
      case '/info/dream-retraining':
        return !!prescription.isNightmareReadOn;
      case '/info/fatigue-countermeasures':
        return !!prescription.isFatigueReadOn;
      case '/info/clock-retraining':
        return !!prescription.isClockReadOn;
      case '/info/stress-reduction-tactics':
        return !!prescription.isStressReadOn;
      case '/info/thought-substitution-training':
        return !!prescription.isThoughtSubstitutionReadOn;
      default:
        return false;
    }
  }

  private getAssessmentsInPhase(phase: TreatmentPhase, isBaselineAssessment?: boolean) {
    const state = this.getState();
    if (isBaselineAssessment) {
      return state.completedAssessments
        ? state.completedAssessments.filter(
            assessment =>
              assessment.phaseName === phase.name &&
              assessment.groups.find(g => g.type === 'isi')
          )
        : [];
    } else if (phase.name === 'baseline') {
      return state.completedAssessments
        ? state.completedAssessments.filter(
            assessment =>
              assessment.phaseName === phase.name &&
              !assessment.groups.find(g => g.type === 'isi')
          )
        : [];
    } else {
      return state.completedAssessments
        ? state.completedAssessments.filter(assessment => assessment.phaseName === phase.name)
        : [];
    }
  }

  public handleUserAction(userAction: UserAction) {
    const state = this.getState();

    switch (userAction.userActionType) {
      case UserActionType.assessment.name:
        if (userAction.questions) {
          state.currentAssessmentQuestions = userAction.questions;
          state.currentAssessmentTerm = userAction.phaseName;
          this.setState(state);
          this.router.navigateByUrl(userAction.route);
        }
        break;
      case UserActionType.baselineAssessment.name:
        if (userAction.questions) {
          state.currentAssessmentQuestions = userAction.questions;
          state.currentAssessmentTerm = userAction.phaseName;
          this.setState(state);
          this.router.navigateByUrl(userAction.route);
        }
        break;
      case UserActionType.logs.name:
      case UserActionType.readItem.name:
      case UserActionType.acknowledge.name:
      case UserActionType.quickStart.name:
      default:
        this.router.navigateByUrl(userAction.route);
    }
  }

  public isUserActionComplete(userAction: UserAction, phase?: TreatmentPhase): boolean {
    switch (userAction.userActionType) {
      case UserActionType.assessment.name:
        return this.isAssessmentActionComplete(phase);
      case UserActionType.baselineAssessment.name:
        return this.isAssessmentActionComplete(phase, true);
      case UserActionType.readItem.name:
        return this.isReadItemComplete(userAction, phase);
      case UserActionType.quickStart.name:
        const user: any = this.userStateService.model.get().User;
        if (user) {
          return !!user.applicationData && !!user.applicationData.quickStartReadDate;
        } else {
          return false;
        }
      default:
        return false;
    }
  }

  public setIsModalDisplayed(isDisplayed: boolean) {
    const state = this.getState();
    state.isModalDisplayed = isDisplayed;
    this.setState(state);
  }

  public splitAssessments(
    questions: Array<AssessmentQuestion>
  ): Array<{ assessmentName: string; questions: Array<AssessmentQuestion> }> {
    const splitAssessments: Array<{
      assessmentName: string;
      questions: Array<AssessmentQuestion>;
    }> = [];
    let assessment: { assessmentName: string; questions: Array<AssessmentQuestion> };
    if (!questions || questions.length === 0) {
      return [];
    }
    for (const question of questions) {
      if (!assessment) {
        assessment = { assessmentName: question.assessmentName, questions: [] };
      }

      if (question.assessmentName === assessment.assessmentName) {
        assessment.questions.push(question);
      } else {
        splitAssessments.push(assessment);
        assessment = { assessmentName: question.assessmentName, questions: [question] };
      }
    }
    if (assessment) {
      splitAssessments.push(assessment);
    }

    return splitAssessments;
  }

  private mapCalendarEventsToPhases(events) {
    const phases = events.map((event, index) => {
      const conditionalAssessments = [];
      const phaseDef = treatmentPlanPhases[event.phaseName];
      if(phaseDef && phaseDef.conditionalAssessments) {
        conditionalAssessments.push(...phaseDef.conditionalAssessments)
      }
      const trimmedTitle = event.title.substring(event.title.lastIndexOf(']') + 2); // Trim leading [FnameLname] from title

      // add trauma conditional for week phases
      if (!phaseDef && event.phaseName.includes('week')) {
        conditionalAssessments.push({
          conditionPhase: 'baseline',
          conditionAssessment: AssessmentTemplate.trauma.name,
          conditionField: 'TRAUMA',
          options: [
            {
              value: true,
              assessmentsToAdd: [AssessmentTemplate.pcPtsd.name],
              assessmentsToRemove: [],
              addAfterAssessment: AssessmentTemplate.gad2.name
            },
            {
              value: false,
              assessmentsToAdd: [],
              assessmentsToRemove: [AssessmentTemplate.pcPtsd.name],
              addAfterAssessment: AssessmentTemplate.gad2.name
            }
          ]
        })
      }



      return {
        name: event.phaseName,
        label: trimmedTitle,
        order: index + 1,
        assessments: event.assessments,
        conditionalAssessments,
        baselineAssessments: phaseDef?.baselineAssessments
      }
    })
    return phases;
  }

  private shuffleArray(array: Array<any>) {
    let currentIndex = array.length,
      temporaryValue,
      randomIndex;

    // While there remain elements to shuffle...
    while (0 !== currentIndex) {
      // Pick a remaining element...
      randomIndex = Math.floor(Math.random() * currentIndex);
      currentIndex -= 1;

      // And swap it with the current element.
      temporaryValue = array[currentIndex];
      array[currentIndex] = array[randomIndex];
      array[randomIndex] = temporaryValue;
    }

    return array;
  }

  public getRandomSleepFact(phase: TreatmentPhase): string {
    const state = this.getState();
    const factCount = state.sleepFacts.length;
    const phaseNames = state.phases.map(phase => phase.name);
    const phaseIndex = phaseNames.indexOf(phase.name);
    const factIndex = phaseIndex % factCount;
    return state.sleepFacts[factIndex];
  }

  public updateAlertMessage(message: string) {
    const state = this.getState();
    state.alertMessage = message;
    this.setState(state);
  }

  public tacticNameToReadOnProp(tacticName: string) {
    let property;
    if (tacticName === 'sleep-intensity-training') {
      property = 'isRestlessReadOn';
    } else if (tacticName === 'thinking-efficiency-retraining') {
      property = 'isThinkingReadOn';
    } else if (tacticName === 'dream-retraining') {
      property = 'isNightmareReadOn';
    } else if (tacticName === 'fatigue-countermeasures') {
      property = 'isFatigueReadOn';
    } else if (tacticName === 'stress-reduction-tactics') {
      property = 'isStressReadOn';
    } else if (tacticName === 'clock-retraining') {
      property = 'isClockReadOn';
    } else if (tacticName === 'thought-substitution-training') {
      property = 'isThoughtSubstitutionReadOn';
    } else {
      return null;
    }
    return property;
  }

  public completeTactic(tacticName: string) {
    const state = this.getState();
    const property = this.tacticNameToReadOnProp(tacticName);
    if (!property) {
      return;
    }

    state.currentSleepPrescription[property] = new Date().toISOString();
    this.setState(state);
    const sleepRxResponse = this.sleepPrescriptionService
      .save(state.currentSleepPrescription)
      .subscribe();
  }

  public completeCachedTactic(tacticName: string, completionDate: string) {
    const state = this.getState();
    const property = this.tacticNameToReadOnProp(tacticName);
    if (!property) {
      return;
    }
    state.currentSleepPrescription[property] = completionDate;
    this.setState(state);
    return this.sleepPrescriptionService.save(state.currentSleepPrescription);
  }

  public async completeCalendarEvent(calendarEventName: string) {
    const state = this.getState();
    let calendarEvent: CalendarEvent = state.calendarEvents
      ? state.calendarEvents.find(event => event.phaseName === calendarEventName)
      : null;
    if (calendarEvent) {
      calendarEvent.isComplete = true;
      calendarEvent.completedOn = new Date().toISOString();
      calendarEvent = await this.calendarEventService.saveAsync(calendarEvent);
    }

    return calendarEvent;
  }

  public getInProgressAssessmentMeta(
    term: TreatmentPhase,
    questions: Array<AssessmentQuestion>
  ): Meta {
    const state = this.getState();
    const inProgressAssessments = state.inProgressAssessments.filter(
      a =>
        !!a.groups && a.groups.length > 0 && !!a.groups[0].answers && a.groups[0].answers.length > 0
    );
    const assessment = inProgressAssessments.find(
      a =>
        a.phaseName === term.name &&
        a.groups[0].answers[0].uniqueAnswerId === questions[0].uniqueAnswerId
    );
    if (assessment) {
      return assessment.__i;
    } else {
      return null;
    }
  }

  public buildFollowupUserAction(phase: TreatmentPhase) {
    const completedAssessments = this.getAssessmentsInPhase(phase);
    const groups = this.getGroupsForAssessments(completedAssessments);
    const assessments = this.filterAssessmentsOnly(phase);
    const incompleteAssessments = this.filterIncompleteAssessmentsOnly(assessments, groups, phase);

    if (this.isAssessmentAvailable(phase)) {
      const userAction = this.buildAssessmentUserAction(
        assessments,
        UserActionType.assessment,
        incompleteAssessments,
        phase
      );

      userAction.isComplete = incompleteAssessments.length === 0;
      return userAction;
    }
  }
  public handleRedirectsWithSpecificValues(
    question: AssessmentQuestion,
    questions: Array<AssessmentQuestion>
  ): void {
    question.redirectOptions.forEach(ro => {
      if (ro.answerValues) {
        const matchingValues = [];
        ro.answerValues.forEach(av => {
          // Get answer to each question in answerValues
          const questionAnswer = questions.find(a => a.uniqueAnswerId === av.uniqueAnswerId);

          if (av.value !== undefined && questionAnswer.answer === av.value) {
            matchingValues.push(av);
          }
          if (av.values && questionAnswer.answer !== undefined) {
            const match = av.values.find(v => v === questionAnswer.answer);
            if (match) {
              matchingValues.push(av);
            }
          }
        });

        // Make sure all of the answers to the questions match the ones in answerValues
        if (matchingValues.length === ro.answerValues.length) {
          ro.assessmentsToAdd.forEach(asmt => {
            const assessmentQuestions = this.assessmentDefinitionService.getByName(asmt).questions;

            assessmentQuestions.forEach(q => {
              q.assessmentName = asmt;
              questions.push(q);
            });
          });
        }
      }
    });
  }
}

